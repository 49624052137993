import Constants from 'expo-constants';
import { Platform } from 'react-native';

import { VERSION } from './src/version';

export const configs = {
	debug: process.env.NODE_ENV !== 'production',
	development: process.env.NODE_ENV !== 'production',

	// App Developer
	author: 'BlueEast',

	// Website URL of App Developer
	authorUrl: 'https://www.blueeast.com',

	// App Title
	title: 'Mevris Admin',

	// App Version
	version: VERSION,

	// Apollo Graphql Configs
	'plugin.apollo.httpLinkOptions': {
		uri: Constants.expoConfig?.extra?.graphqlUrl,
	},

	'plugin.apollo.wsLinkOptions': {
		uri: Constants.expoConfig?.extra?.graphqlWsUrl,
	},

	// Config Persist Cache
	'plugin.config-persist.key': 'mevris-admin-config',
	// 'plugin.config-persist.encryptionEnable': true,
	// 'plugin.config-persist.encryptionKey': '123',

	'user-management.login.title': 'Mevris Admin',

	'user-management.portal.url': Constants.expoConfig?.extra?.accountPortalUrl,

	'user-management.keycloak': {
		url: Constants.expoConfig?.extra?.keycloak?.url,
		realm: Constants.expoConfig?.extra?.keycloak?.realm,
		scheme: Constants.expoConfig?.extra?.keycloak?.scheme,
		clientId: Platform.select({
			android: Constants.expoConfig?.extra?.keycloak?.androidClientId,
			ios: Constants.expoConfig?.extra?.keycloak?.iosClientId,
			web: Constants.expoConfig?.extra?.keycloak?.webClientId,
		}),
	},
};
