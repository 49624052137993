import MevrisLocalDeviceController from '@mevris/device-controller-mevris-local';
import MevrisRemoteDeviceController from '@mevris/device-controller-mevris-remote';

import { configs } from './configs';
import { plugins } from './plugins';

export default {
	configs,
	plugins,

	deviceControllers: {
		MevrisLocalDeviceController,
		MevrisRemoteDeviceController,
	},
};
